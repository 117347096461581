export enum LicenseType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum LicenseVersion {
  '0.0.1' = '0.0.1',
  '2.1.42' = '2.1.42',
  '2.1.43' = '2.1.43',
  '2.1.44' = '2.1.44',
  '2.1.45' = '2.1.45',
  '2.1.46' = '2.1.46',
  '2.1.47' = '2.1.47',
  '2.1.48' = '2.1.48',
  '2.1.49' = '2.1.49',
  '2.1.50' = '2.1.50',
  '2.1.51' = '2.1.51',
  '2.1.52' = '2.1.52',
  '2.1.53' = '2.1.53',
  '2.2.0' = '2.2.0',
  '2.2.1' = '2.2.1',
  '2.2.2' = '2.2.2',
  '2.2.3' = '2.2.3',
  '2.2.22' = '2.2.22',
  '2.2.35' = '2.2.35',
  '4.0.17' = '4.0.17',
  '4.2.26' = '4.2.26',
  '4.2.31' = '4.2.31',
  '4.2.46' = '4.2.46',
  '4.2.47' = '4.2.47',
  '4.3.1' = '4.3.1',
  '4.3.2' = '4.3.2',
  '4.4.1' = '4.4.1',
  '4.4.2' = '4.4.2',
  '4.4.3' = '4.4.3',
  '4.4.4' = '4.4.4',
  '4.4.5' = '4.4.5',
  '4.4.6' = '4.4.6',
  '4.4.7' = '4.4.7',
  '4.4.8' = '4.4.8',
  '4.4.9' = '4.4.9',
  '4.4.10' = '4.4.10',
  '4.4.11' = '4.4.11',
  '4.4.12' = '4.4.12',
  '4.5.1' = '4.5.1',
  '4.6.1' = '4.6.1',
  '4.6.2' = '4.6.2',
  '4.6.3' = '4.6.3',
  '4.6.4' = '4.6.4',
  '4.6.5' = '4.6.5',
  '4.7.1' = '4.7.1',
  '4.7.2' = '4.7.2',
  '4.7.3' = '4.7.3',
  '4.7.4' = '4.7.4',
  '4.7.5' = '4.7.5',
  '4.8.1' = '4.8.1',
  '4.8.2' = '4.8.2',
  '4.8.3' = '4.8.3',
  '4.9.1' = '4.9.1',
  '4.10.1' = '4.10.1',
  '4.10.2' = '4.10.2',
  '4.10.3' = '4.10.3',
  '4.10.4' = '4.10.4',
  '4.12.1' = '4.12.1',
  '4.12.2' = '4.12.2',
  '4.12.3' = '4.12.3',
  '4.12.4' = '4.12.4',
  '4.12.5' = '4.12.5',
  '4.12.6' = '4.12.6',
  '4.12.7' = '4.12.7',
  '4.12.8' = '4.12.8',
  '4.12.9' = '4.12.9',
  '4.12.10' = '4.12.10',
  '4.12.11' = '4.12.11',
  '4.12.12' = '4.12.12',
  '4.12.13' = '4.12.13',
  '4.12.14' = '4.12.14',
  '4.12.15' = '4.12.15',
  '4.12.16' = '4.12.16',
  '4.12.17' = '4.12.17',
  '4.12.18' = '4.12.18',
  '4.12.19' = '4.12.19',
  '4.12.20' = '4.12.20',
  '4.12.21' = '4.12.21',
  '4.13.1' = '4.13.1',
  '4.13.2' = '4.13.2',
  '4.13.3' = '4.13.3',
  '4.13.4' = '4.13.4',
  '4.13.5' = '4.13.5',
  '4.13.6' = '4.13.6',
  '4.13.7' = '4.13.7',
  '4.13.8' = '4.13.8',
  '4.13.9' = '4.13.9',
  '4.13.10' = '4.13.10',
  '4.13.11' = '4.13.11',
  '4.13.12' = '4.13.12',
  '4.13.13' = '4.13.13',
  '4.13.14' = '4.13.14',
  '4.13.15' = '4.13.15',
  '4.13.16' = '4.13.16',
  '4.13.17' = '4.13.17',
  '4.13.18' = '4.13.18',
  '4.13.19' = '4.13.19',
  '4.13.20' = '4.13.20',
  '4.13.21' = '4.13.21',
  '4.13.22' = '4.13.22',
  '4.14.1' = '4.14.1',
  '4.14.2' = '4.14.2',
  '4.14.3' = '4.14.3',
  '4.14.4' = '4.14.4',
  '4.15.1' = '4.15.1',
  '4.15.2' = '4.15.2',
  '4.15.3' = '4.15.3',
  '4.15.4' = '4.15.4',
  '4.15.5' = '4.15.5',
  '4.15.6' = '4.15.6',
  '4.16.1' = '4.16.1',
  '4.16.2' = '4.16.2',
  '4.16.3' = '4.16.3',
  '4.16.4' = '4.16.4',
  '4.16.5' = '4.16.5',
  '4.16.6' = '4.16.6',
  '4.16.7' = '4.16.7',
  '4.16.8' = '4.16.8',
  '4.17.1' = '4.17.1',
  '4.17.2' = '4.17.2',
  '4.17.3' = '4.17.3',
  '4.17.4' = '4.17.4',
  '4.17.5' = '4.17.5',
  '4.17.6' = '4.17.6',
  '4.17.7' = '4.17.7',
  '4.18.1' = '4.18.1',
  '4.18.2' = '4.18.2',
  '4.18.3' = '4.18.3',
  '4.18.4' = '4.18.4',
  '4.19.1' = '4.19.1',
  '4.20.1' = '4.20.1',
  '4.20.2' = '4.20.2',
  '4.20.3' = '4.20.3',
  '4.20.4' = '4.20.4',
  '4.20.5' = '4.20.5',
  '4.20.6' = '4.20.6',
  '4.20.7' = '4.20.7',
  '4.21.1' = '4.21.1',
  '4.21.2' = '4.21.2',
  '4.21.3' = '4.21.3',
  '4.21.4' = '4.21.4',
  '4.21.5' = '4.21.5',
  '4.21.6' = '4.21.6',
  '4.21.7' = '4.21.7',
  '4.21.8' = '4.21.8',
  '4.22.1' = '4.22.1',
  '4.22.2' = '4.22.2',
  '4.22.3' = '4.22.3',
  '4.22.4' = '4.22.4',
  '4.22.5' = '4.22.5',
  '4.22.6' = '4.22.6',
  '4.22.7' = '4.22.7',
  '4.22.8' = '4.22.8',
  '4.22.9' = '4.22.9',
  '4.22.10' = '4.22.10',
  '4.22.11' = '4.22.11',
  '4.22.12' = '4.22.12',
  '4.22.13' = '4.22.13',
  '4.22.14' = '4.22.14',
  '4.22.15' = '4.22.15',
  '4.22.16' = '4.22.16',
  '4.22.17' = '4.22.17',
  '4.22.18' = '4.22.18',
  '4.22.19' = '4.22.19',
  '4.23.1' = '4.23.1',
  '4.24.1' = '4.24.1',
  '4.24.2' = '4.24.2',
  '4.24.4' = '4.24.4',
  '4.24.5' = '4.24.5',
  '4.24.6' = '4.24.6',
  '4.24.7' = '4.24.7',
  '4.24.8' = '4.24.8',
  '4.24.9' = '4.24.9',
  '4.25.1' = '4.25.1',
  '4.25.2' = '4.25.2',
  '4.25.3' = '4.25.3',
  '4.25.4' = '4.25.4',
  '4.25.5' = '4.25.5',
  '4.25.6' = '4.25.6',
  '4.25.7' = '4.25.7',
  '4.26.1' = '4.26.1',
  '4.26.2' = '4.26.2',
  '4.26.3' = '4.26.3',
  '4.26.4' = '4.26.4',
  '4.26.5' = '4.26.5',
  '4.26.6' = '4.26.6',
  '4.27.1' = '4.27.1',
  '4.28.1' = '4.28.1',
  '4.28.2' = '4.28.2',
  '4.28.3' = '4.28.3',
  '4.28.4' = '4.28.4',
  '4.28.5' = '4.28.5',
  '4.28.6' = '4.28.6',
  '4.28.7' = '4.28.7',
  '4.28.8' = '4.28.8',
  '4.28.9' = '4.28.9',
  '4.28.10' = '4.28.10',
  '4.28.11' = '4.28.11',
  '4.28.12' = '4.28.12',
  '4.28.13' = '4.28.13',
  '4.28.14' = '4.28.14',
  '4.29.1' = '4.29.1',
  '4.29.2' = '4.29.2',
  '4.29.3' = '4.29.3',
  '4.29.4' = '4.29.4',
  '4.29.5' = '4.29.5',
  '4.30.2' = '4.30.2',
  '4.31.0' = '4.31.0',
  '4.31.1' = '4.31.1',
  '4.31.2' = '4.31.2',
  '4.31.3' = '4.31.3',
  '4.31.4' = '4.31.4',
  '4.31.5' = '4.31.5',
  '4.31.6' = '4.31.6',
  '4.31.7' = '4.31.7',
  '4.31.8' = '4.31.8',
  '4.31.9' = '4.31.9',
  '4.31.10' = '4.31.10',
  '4.31.11' = '4.31.11',
  '4.31.12' = '4.31.12',
  '4.31.13' = '4.31.13',
  '4.31.14' = '4.31.14',
  '4.31.15' = '4.31.15',
  '4.31.16' = '4.31.16',
  '4.31.17' = '4.31.17',
  '4.31.18' = '4.31.18',
  '4.31.19' = '4.31.19',
  '4.31.20' = '4.31.20',
  '4.32.1' = '4.32.1',
  '4.32.2' = '4.32.2',
  '4.33.1' = '4.33.1',
  '4.34.1' = '4.34.1',
  '4.35.1' = '4.35.1',
  '4.35.2' = '4.35.2',
  '4.36.1' = '4.36.1',
}

export interface Limits {
  usersNumber?: number;
  maximumUsersNumber?: number;
  scalesNumber?: number;
  locationsNumber?: number;
  maximumMeasurementsNumber?: number;
  additionalFieldsNumber?: number;
  allowGenerateReports: boolean;
  addWatermark: boolean;
  allowApiAccess: boolean;
  useImagePosReceipts: boolean;
  allowEdgeDeviceConfigurationUpdate: boolean;
  usePolishBDOLanguage: boolean;
  turnOnHttpNodeAuth: boolean;
  turnOnAutomaticZeroing: boolean;
}

export interface License {
  id: string;
  customerId: string;
  email: string;
  deviceKey?: string;
  licenseKey?: string;
  version: LicenseVersion;
  type: LicenseType;
  expirationDate?: Date;
  subscriptionModules: string[];
  companyName: string;
  limits?: Limits;
  remarks?: string;
}
