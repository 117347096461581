import axios from 'axios';
import moment from 'moment';
import { formatStringToDate } from '../../utils/format-string-to-date';

class RecognitionsService {
  async searchRecognitions(filters: any, paginatorParam: any) {
    return await axios.post(`/recognitions/search`, {
      filters: this.formatSearchFilters(filters),
      paginatorParam,
    });
  }

  formatSearchFilters(filters: any) {
    const endOfDayCustom = (date: Date) => {
      return moment(date)
        .endOf('day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    const startOfDateWithTime = (date: Date) => {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    };

    const endOfDateWithTime = (date: Date) => {
      return moment(date).milliseconds(999).format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    const prepareDateFilter = (filters: any) => {
      if (!filters?.date) return filters;

      const [startDate, endDate] = filters.date;

      if (startDate === endDate || !endDate) {
        return {
          ...filters,
          date: [startOfDateWithTime(startDate), endOfDayCustom(startDate)],
        };
      }

      return {
        ...filters,
        date: [startOfDateWithTime(startDate), endOfDateWithTime(endDate)],
      };
    };

    return {
      ...filters,
      date: prepareDateFilter(filters).date?.map((stringDate: string) => formatStringToDate(stringDate)),
    };
  }
}

const recognitionsService = new RecognitionsService();
export default recognitionsService;
